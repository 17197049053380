import { Radio, Space } from 'antd';
import { OutcomeStatus } from 'app/types/OutcomeStatus';
import classNames from 'classnames';
import { Button, ButtonPalette, ButtonSize } from 'components/Button';
import { Modal } from 'components/Modal';
import { ModalBody } from 'components/Modal/ModalBody';
import { ModalFooter } from 'components/Modal/ModalFooter';
import { ModalHeader } from 'components/Modal/ModalHeader';
import { Icons } from 'icons';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { campaignRunAPI } from 'services/Campaigns/CampaignsRunService';
import { usersAPI } from 'services/Hercules/HerculesUsersService';
import { addAddressOutcomeStatus, selectAddress } from 'store/reducers/addressSlice';
import { selectRun } from 'store/reducers/runSlice';
import styles from '../Map.module.scss';
import TextArea from 'antd/es/input/TextArea';
import { FutureLeadForm } from './FutureLeadForm';

export const ModalNoSale = ({ onClose, refetch }: any) => {
    const dispatch = useDispatch();

    const [outcomeValue, setOutcomeValue] = useState(OutcomeStatus.CHURN);
    const onChange = (e: any) => {
        setFutureLeadButton(e.target.value === OutcomeStatus.FUTURE_LEAD);

        setOutcomeValue(e.target.value);
    };

    const [futureLeadData, setFutureLeadData] = useState({});

    const { uuid: storageUuid } = useSelector(selectRun);
    const { uprn } = useSelector(selectAddress);

    const { data: outcomes = [] } = campaignRunAPI.useGetAllOutcomeInRunQuery({ run_uuid: storageUuid }, {
        skip: !storageUuid
    });

    const [createOutcome] = campaignRunAPI.useCreateOutcomeForPropertyMutation();
    const [updateOutcome] = campaignRunAPI.useUpdateOutcomeForPropertyMutation();
    const { data: currentUser = {} }: any = usersAPI.useGetCurrentUserQuery();

    const [isFutureLeadShow, setFutureLeadShow] = useState(false);
    const [isFutureLeadButton, setFutureLeadButton] = useState(false);

    const currentStatus = outcomes.find((el) => el.uprn === uprn);

    const [additionalNotes, setAdditionalNotes] = useState<string>();

    const handleClick = async () => {

        const outcome = (outcomeValue !== OutcomeStatus.NO_ANSWER && outcomeValue !== OutcomeStatus.NO_SALE) ? OutcomeStatus.NO_SALE : OutcomeStatus.NO_ANSWER;

        try {
            if (!currentStatus) {
                await createOutcome({
                    run_uuid: storageUuid,
                    body: {
                        run_uuid: storageUuid,
                        outcome: outcome.toLowerCase(),
                        outcome_at: new Date().toISOString(),
                        uprn,
                        worker_uuid: currentUser.uuid,
                        extra_info: {
                            reason: outcome, notes: additionalNotes,
                            feature_lead: {
                                ...futureLeadData
                            }
                        }
                    }
                });
                refetch();

            } else {
                await updateOutcome({
                    outcome_uuid: currentStatus.uuid,
                    run_uuid: storageUuid,
                    body: {
                        outcome: outcome.toLowerCase(),
                        outcome_at: new Date().toISOString(),
                        worker_uuid: currentUser.uuid,
                        extra_info: {
                            reason: outcome, notes: additionalNotes,
                            feature_lead: {
                                ...futureLeadData
                            }
                        }
                    }
                });
                refetch();
            }
            dispatch(addAddressOutcomeStatus({
                outcome: outcomeValue === OutcomeStatus.NOT_RIGHT_PERSON ? OutcomeStatus.NO_ANSWER : outcomeValue
            }));

        } catch (error) {
            // @ts-ignore
            console.error(error);
        }
    };

    const onNotesChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setAdditionalNotes(e.target.value);
    };
    return (<>
        <Modal className={styles.modal} onClose={() => onClose()}>
            <ModalHeader>
                <div className={styles.decoration}>
                    <Icons.ErrorIcon />
                </div>
                <div className={classNames(styles.textBlock)}>
                    <h2 className={classNames('display-sm', 'text-600', 'text-primary')}>No Sale</h2>
                    <p className={classNames('text-sm', 'text-400', 'text-tertiary')}>Let us know why there was no sale. </p>
                </div>

            </ModalHeader>

            <ModalBody className={styles.gridColumn}>
                <Radio.Group onChange={onChange} value={outcomeValue}>
                    <Space direction="vertical">
                        <Radio value={OutcomeStatus.NO_ANSWER} className={classNames('text-md')}>No answer</Radio>
                        <Radio value={OutcomeStatus.NOT_RIGHT_PERSON} className={classNames('text-md')}>Not the right person</Radio>
                        <Radio value={OutcomeStatus.REQUIRES_VOIP} className={classNames('text-md')}>Requires VOIP</Radio>
                        <Radio value={OutcomeStatus.TOO_EXPENSIVE} className={classNames('text-md')}>Too expensive</Radio>
                        <Radio value={OutcomeStatus.BAD_REVIEWS} className={classNames('text-md')}>Bad reviews</Radio>
                        <Radio value={OutcomeStatus.TOO_FAST} className={classNames('text-md')}>Too fast</Radio>
                        <Radio value={OutcomeStatus.DO_NOT_KNOCK} className={classNames('text-md')}>Do not knock</Radio>
                        <Radio value={OutcomeStatus.VULNERABLE} className={classNames('text-md')}>Vulnerable</Radio>
                        <Radio value={OutcomeStatus.FUTURE_LEAD} className={classNames('text-md')}>
                            <div className={styles.itemButton}> Future lead
                                {isFutureLeadButton && <Button size={ButtonSize.SM}
                                    palette={ButtonPalette.SECONDARY_GRAY} onClick={() => setFutureLeadShow(true)}>Setup lead</Button>}
                            </div>
                        </Radio>
                    </Space>
                </Radio.Group>
                <TextArea rows={4} onChange={onNotesChange} placeholder='Additional notes' />
            </ModalBody>
            <ModalFooter >

                <Button size={ButtonSize.LG}
                    palette={ButtonPalette.SECONDARY_GRAY}
                    onClick={() => {
                        onClose();
                    }}
                >
                    Discard
                </Button>
                <Button size={ButtonSize.LG}
                    palette={ButtonPalette.PRIMARY}
                    onClick={() => {
                        handleClick();
                        onClose();
                    }}
                    isDisabled={!outcomeValue}
                >
                    Save
                </Button>
            </ModalFooter >
        </Modal >

        {isFutureLeadShow && <FutureLeadForm onClose={() => setFutureLeadShow(false)} onChange={setFutureLeadData} />}
    </>
    );
};